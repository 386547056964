import React from "react";

import { useAuth } from "../contexts/Auth";

const Embedded: React.FC = () => {
  console.log("Embedded view");
  const auth = useAuth();

  return (
    <div className="text-center mt-32">
      {auth?.currentUser?.partner ? (
        <>
          <h3 className="mt-2 text-lg font-semibold text-gray-900">
            {auth.currentUser.partner.name}
          </h3>
        </>
      ) : (
        <>
          <h3 className="mt-2 text-lg font-semibold text-gray-900">
            Your account is not enabled for Embedded access.
          </h3>
          <p className="mt-2 text-sm text-gray-500">
            Please reach out to{" "}
            <a href="mailto:support@ansiblelabs.xyz">support@ansiblelabs.xyz</a>{" "}
            to request access.
          </p>
        </>
      )}
    </div>
  );
};

export default Embedded;
